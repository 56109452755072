import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { MustMatch } from '../../Controllers/mustmatch';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { error } from '@angular/compiler/src/util';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
 @Component({
  selector: 'app-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['./password-reset-request.component.css']
})
export class PasswordResetRequestComponent implements OnInit {

  constructor(public authService: AuthService,
    public router : Router,
    public RouterActive : ActivatedRoute,
    private spinner: NgxSpinnerService) {
  }
  modal :any={};

  gotonavigate(){
    this.router.navigate([''+this.authService.islogin]);
  }

  bhagDKBOSS(type,msg){
    this.spinner.hide();
    Swal.fire({
      title: this.authService. MessageTitle,
      text: msg,
      icon: type,
      showCancelButton: false,
      allowOutsideClick:false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK!'
    }).then((result) => {
      this.gotonavigate();
    })
  }
  navigating(){
    this.router.navigate([this.authService.islogin]);
  }

  heroForm : FormGroup;


  checkPasswords(group: FormGroup)
  {
  let pass = group.controls.password.value;
  let confirmPass = group.controls.confirmPassword.value;
  return pass === confirmPass ? null : { notSame: true }
}



  get Password(){
  if (this.heroForm.value.Password !== this.heroForm.value.conformPassword) {
    this.heroForm.get("conformPassword").setErrors({ mustMatch: true });
}else    if(this.heroForm.get("conformPassword").hasError("required"))   {
this.heroForm.get("conformPassword").setErrors({ required: true });

}
else{
  this.heroForm.get("conformPassword").setErrors(null);

}

  return this.heroForm.get("Password");
}
get conformPassword(){
// // //console.log(this.Password.value)
// // //console.log(this.heroForm.value.conformPassword)
  if (this.Password.value !== this.heroForm.value.conformPassword) {
     this.heroForm.get("conformPassword").setErrors({ mustMatch: true });
}


    return this.heroForm.get("conformPassword");

}


  get email() { return this.heroForm.get('email'); }
show:boolean=false;
successMessageDisp(type,msg){
  this.spinner.hide();
      Swal.fire(
    this.authService.MessageTitle,
       msg,
     type
      );
    }
  login(){
    this.spinner.show();
    this.heroForm.markAllAsTouched();
    if(this.heroForm.valid){
      if(this.heroForm.value.Password==this.heroForm.value.conformPassword)
      {
    this.modal.pass = this.heroForm.value.Password;
    this.modal.conpass = this.heroForm.value.conformPassword;
    this.modal.action = "checkpassvalidate";
    this.authService.updategooglecounter(this.modal).subscribe((data)=>{
      // //console.log(data);
      if(data.status=="success"){
        this.bhagDKBOSS("success",data.data)
      }
      else{
        this.successMessageDisp("error",data.data)

      }
      this.spinner.hide();
      this.show = false;
      this.heroForm.reset();
    },
    (err)=>{
      // //console.log(err);
      this.spinner.hide();
      this.show = false;
      this.successMessageDisp("error","Internal Server Error,Try Again Or Contact Adminstrator.")
    });


  }
  else{
this.successMessageDisp("error","Password And Conform Password Do Not Match . Try Again")
  }
}else{
  this.successMessageDisp("error","Invalid Input Provided. Try Again")

}
  }
    ngOnInit(): void {
      this.spinner.show();
      // this. profileForm = new FormGroup({

      //   password: new FormControl('',[Validators.required,
      //     Validators.maxLength(50)]),
      // });

      this.authService.setTitleReBaba(this.authService.isPassowrdResetReq);

      this.RouterActive.queryParams
      .subscribe(params => {
        if(params.session!=undefined&&params.email!=undefined&&params.key!=undefined){
          this.modal.action = "loadchecking";
          this.modal.email = params.email;
          this.modal.session = params.session;
          this.modal.key = params.key;
           this.heroForm =   new FormGroup({
            email: new FormControl({value:params.email,disabled: true},[Validators.required,
              Validators.maxLength(50)],

              ),
            conformPassword: new FormControl('', [
              Validators.required,
              Validators.maxLength(50)
            ]),

            Password: new FormControl('', [
              Validators.required,
              Validators.minLength(8),
              Validators.maxLength(50)
            ])
          }, {validators:MustMatch});
          this.authService.updategooglecounter(this.modal).subscribe((data)=>{

            if(data.status=="success"){
            this.show = true;
          }else{
            this.bhagDKBOSS("error",data.data);
            this.show = false;
          }
          this.spinner.hide();

          },
          (err)=>{
            this.bhagDKBOSS("error","Internal Server Error . Please Try Again For Password Request.")
            this.spinner.hide();
            this.show = false;
          });
        }
        else{
          this.bhagDKBOSS("error","Invalid Password Reset Request . Please Try Again For Password Request.")
          this.show= false;
        }
      });
    }

  }
