// auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private decoder :JwtHelperService,
  ) { }

  alertMessageDisplay(msg :string,type:any){
    Swal.fire(
      'Message',
      msg,
  type
    );}

  canActivate() {
    console.log(this.authService.isLoggedIn());
    console.log(this.authService.getUsertokens())
    console.log(this.decoder.isTokenExpired(this.authService.getUsertokens()))
    console.log((!this.authService.isLoggedIn()) )
    if ((!this.authService.isLoggedIn())  ||this.decoder.isTokenExpired(this.authService.getUsertokens())) {

      this.router.navigate(['/Login']).then((E)=>{
        localStorage.clear();
        this.alertMessageDisplay("Your Session Is Expired. Please Login In Again","error");
      });
      return false;
    } else {
      return true;
    }
  }

}
