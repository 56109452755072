import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES, ROUTESFOREMPLOYEES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { param } from 'jquery';
import { AuthService } from '../../services/auth.service';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  menu_indicate(clickedItem){
    
    let url = this.router.url.substr(1)
    if(clickedItem === url){
      return true;
    }
    return false;
  }
  menu_highlight(clickedItem:string){
   
    let url = this.router.url;
    let params = url.split("/")[0];
    let item = clickedItem.substr(1);

    if(params === item){
      return true;
    }
    return false;
  }
  parent_menu_indicate(clickedItem){
    
    let url = this.router.url;//.substr(1)
     if(clickedItem === url){
      return true;
    }
    return false;
  }
  parent_menu_collapse(clickedItem){
    
    let url = this.router.url;//.substr(1)
     if(clickedItem === url){
      return "in";
    }
    return "";
  }
  validatehighlight(val1){
    let url = this.router.url;
    let params = url.split("/")[1];
    let item = val1.substr(1);
    if(params === item ){
    return true;
    }else{return false;}
  }
  collospe(val1){
    let url = this.router.url;
    let params = url.split("/")[1];
    let item = val1.substr(1);
    if(params === item ){
    return true;
    }else{return false;}
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(private modalService: NgbModal, private router: Router, private route: ActivatedRoute,
    public authService :AuthService,
  ) {}
  // End open close
  logout(){
    this.authService.logout("Your Are Logged Out","info",1);
  }
  username :string = "";
  image :string = "";
  date : string = "";
  addClass = "";
  

  validateImage(img){
    if(img=="data:image/png;base64,*")
    {
return "../../assets/dist/img/avatar.png";
    }
    else{
      return img;
    }
  }
  ngOnInit(): void {
    if(this.authService.getusertypestatus()=="Admin"){
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }
    else{
      this.sidebarnavItems = ROUTESFOREMPLOYEES.filter(sidebarnavItem => sidebarnavItem);
    }
    this.username = this.authService.getUserName();
    this.date = this.authService.getUserDate();
    // this.image =;
    // this.Dashboards = RouterActive.isDashboard;
    // this.Videos =  RouterActive.isVideo;
    // this.Channels =  RouterActive.isChannel;
    // this.isVideoKeyword = RouterActive.isVideokeyword;
    // this.isVideoChannel =RouterActive.isVideoChannelSearch;
    // this.isVideoTopCharts = RouterActive.isTopCharts;
    // this.isVideoRelated = RouterActive.isRelatedVideo;
    // this.isBookmarkComingSoon = RouterActive.isBookmarkComingSoon;
    // this.isGoogleAdsComingSoon = RouterActive.isGoogleAdsComingSoon;
    // this.isVideotrending = RouterActive.isTrending;
    // this.isChannelsKeyword = RouterActive.isChannelKeyword;
    // this.isFeaturedChannel = RouterActive.isFeaturedChannel;
    // this.isChannelInfo = RouterActive.isChannelInfo;
    // this.isVideoInfo = RouterActive.isVideoInfo;
    // this.BookMark=RouterActive.isBookmark;
    // this.isBookmarkAds=RouterActive.isBookmarkAds;
    // this.isBookmarkVideo=RouterActive.isBookmarkVideos;
    // this.YoutubePlacement = RouterActive.isYoutubePlacment;
    // this.isGoogleSaveList = RouterActive.isGoogleSaveList;
    // this.isGoogleSearchList = RouterActive.isGoogleSearchList;
    // this.isNotifications=RouterActive.isNotifications;
    // this.iskeywordSearched=RouterActive.iskeywordSearched;
  }

    Dashboards= "";
    isGoogleAdsComingSoon =";"
    Videos = "";
    Channels = "";
    isChannelsKeyword = "";
    isFeaturedChannel = "";
    isVideoKeyword = "";
    isVideoChannel = "";
    isVideoTopCharts = "";
    isVideoRelated = "";
    isVideotrending = "";
    isChannelInfo = "";
    isVideoInfo="";
    isGoogleSaveList="";
    isBookmarkAds="";
    isBookmarkVideo="";
    isBookmarkComingSoon="";
    BookMark="";
    isGoogleSearchList="";
    isNotifications="";
    iskeywordSearched="";
    YoutubePlacement="";
    videoCheck(){
    //   if(  RouterActive.isVideo == ""){
    //     RouterActive.isVideo = "menu-open active";
    //   }
    //   else{
    //     RouterActive.isVideo = ""
    //   }
    //  this.Videos =  RouterActive.isVideo
    }

    channelCheck(){
    //   if(  RouterActive.isChannel == ""){
    //     RouterActive.isChannel = "menu-open active";
    //   }
    //   else{
    //     RouterActive.isChannel = ""
    //   }
    //  this.Channels =  RouterActive.isChannel
    }

    bookmarkCheck(){
    //   if(  RouterActive.isBookmark == ""){
    //     RouterActive.isBookmark = "menu-open active";
    //   }
    //   else{
    //     RouterActive.isBookmark = ""
    //   }
    //  this.BookMark =  RouterActive.isBookmark
    }

  navigate(path){
    this.authService.validateUserToNavigate(path);
  }

}

