<ngx-spinner 
 style="position: relative;right:30%"  
bdColor ="rgba(0,0,0,0.5)" size = "medium"

 color = "white" type = "timer" [fullScreen]= "true">
 <br>
 <p style="color: white" >  </p></ngx-spinner>

<div class="login-register"
  style="background:url('../../../assets/images/bg-login-img.jpg');background-size: cover; background-position: center;">
  <div class="login-box card" style="border-radius: 22px;background-color: #191C24;">
    <div class="card-body">
      <form class="form-horizontal form-material login-form form-validate" [formGroup]="profileForm" #formDir="ngForm"
        (ngSubmit)="login()">

        <p class="text-center">
          <img src="../../../assets/images/logo-full.png" style="width:200px" alt="">
        </p> <br>
        <h3 class="box-title m-b-20 text-center">
     
          <small class="text-white">
          HRM  Login
          </small>
        </h3>

        <div class="form-group has-feedback">
          <input type="email" class="form-control" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" title="Email Is Required"
            id="email" formControlName="email" required placeholder="Email">
          <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
          <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger" style="padding: 5px;">
            <div *ngIf="email.errors.required">
              Email is required. <i class="icon-warning22"></i>
            </div>
            <div *ngIf="email.errors.pattern">
              Provided text does not match email format.
            </div>
          </div>
        </div>


        <div class="form-group has-feedback">
          <input type="password" class="form-control" formControlName="password" name="password" placeholder="Password">

          <span class="glyphicon glyphicon-lock form-control-feedback" required></span>
          <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger"
            style="padding: 5px;">
            <div *ngIf="password.errors.required">
              Password is required. <i class="icon-warning22"></i>
            </div>

          </div>
        </div>


        <div class="form-group row">
          <div class="col-md-12 font-14">
            <div class="checkbox checkbox-primary pull-left p-t-0">
              <input id="checkbox-signup" type="checkbox">
              <label for="checkbox-signup"> &nbsp; Remember me </label>
            </div>
            <a (click)="navigating()" id="to-recover" class=" pull-right " style="color:#E3A45E; cursor: pointer;">
              <i class="fa fa-lock m-r-5"></i> <b>Forgot password?</b> </a>
          </div>
        </div>
        <div class="form-group text-center m-t-20">
          <div class="col-xs-12">
            <button [disabled]="!profileForm.valid" type="submit"
              class="btn btn-my btn-lg btn-block text-uppercase waves-effect waves-light">
              Sign in <i class="icon-circle-right2 ml-2"></i>
            </button>
          </div>
        </div>

 
      </form>

    </div>
  </div>
</div>