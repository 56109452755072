<!-- User profile -->

<div class="user-profile">
    <!-- User profile image -->
    <div class="profile-img">
        <img src="{{validateImage(this.authService.rawImageCode)}}"  alt="user" />
        <!-- this is blinking heartbit-->
        <div class="notify setpos">
            <span class="heartbit"></span>
            <span class="point"></span>
        </div>
    </div>
    <!-- User profile text-->
    <div class="profile-text">
        <h5>{{this.username}}</h5>

        <!-- <a href="https://supportcenter.madsgency.com/support/home" target="_blank" 
        class="" data-toggle="tooltip" title="Support">
            <i class="fa fa-envelope"></i>
        </a> -->
        <a  (click)="logout()" data-toggle="tooltip" title="Logout">
            <i class="mdi mdi-power"></i>
        </a>
    </div>
</div>
<!-- End User profile text-->
<li class="nav-devider"></li>
<!-- Sidebar navigation-->
<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li [class.active]="validatehighlight(sidebarnavItem.path)" *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
                {{ sidebarnavItem.title }}
        <li class="nav-ds"></li>
        </div>
        <a [class.active]="parent_menu_indicate(sidebarnavItem.path)"
            [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null" [ngClass]="[sidebarnavItem.class]"
            *ngIf="!sidebarnavItem.extralink" (click)="addExpandClass(sidebarnavItem.title)">
            <i [ngClass]="[sidebarnavItem.icon]"></i>
            <span class="hide-menu">{{ sidebarnavItem.title }}
                <span *ngIf="sidebarnavItem.label != ''" [ngClass]="[sidebarnavItem.labelClass]">{{ sidebarnavItem.label
                    }}</span>
            </span>
        </a>
        <!-- Second level menu -->
        <ul class="collapse" *ngIf="sidebarnavItem.submenu.length > 0"
            [ngClass]="{ in:  showMenu === sidebarnavItem.title }">

            <li *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
                [class.active]="menu_indicate(sidebarnavSubItem.path)" [routerLinkActive]="
            sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                <a [routerLink]="
              sidebarnavSubItem.submenu.length > 0
                ? null
                : [sidebarnavSubItem.path]
            " [routerLinkActive]="
              sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'
            " [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink"
                    [class.active]="menu_indicate(sidebarnavSubItem.path)"
                    (click)="addActiveClass(sidebarnavSubItem.title)">
                    <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                    {{ sidebarnavSubItem.title }}
                </a>
                <!-- Third level menu -->
                <ul class="collapse" *ngIf="sidebarnavSubItem.submenu.length > 0"
                    [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                    <li *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu" routerLinkActive="active"
                        [ngClass]="[sidebarnavSubsubItem.class]">
                        <a [routerLink]="[sidebarnavSubsubItem.path]" *ngIf="!sidebarnavSubsubItem.extralink;"
                            [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                            <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                            {{sidebarnavSubsubItem.title}}
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
        </li>
    </ul>
</nav>
<!-- End Sidebar navigation -->