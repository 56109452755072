<div class="card">
    <div class="card-body">
        <!-- <ngx-spinner 
  name="listnamelo" style="position: relative;right:30%" bdColor="rgba(0,0,0,0.5)" size="medium" color="#123d6b" type= "square-jelly-box"
        [fullScreen]="true">
        <p style="color: white">  </p>
      </ngx-spinner> -->
      
        <form [formGroup]="videosearchform" (ngSubmit)="searchvideos()">

            <div class="row">
                <div class="col-sm-10">
                    <input type="text" class="form-control" style="padding: 20px 30px;" formControlName="searchKeyword"
                        placeholder="Enter Your Valuable Feedback here">
                    <!-- <div *ngIf="searchKeyword.invalid && (searchKeyword.dirty || searchKeyword.touched)"
                        class="alert alert-danger" style="padding: 5px;">
                        <div *ngIf="searchKeyword.errors.required">
                            Some Content is required <i class="icon-warning22"></i>
                        </div>

                    </div> -->
                </div>
                <div class="col-sm-2">
                    <button type="submit" [disabled]="!videosearchform.valid" class="btn btn-danger Feedbackbtn"> <i
                            class="fa fa-paper-plane"></i> Send Feedback</button>


                </div>
            </div>

        </form>

    </div>
</div>