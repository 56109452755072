import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit  {
  subscription: Subscription;
  isDashbaord = '/Dashboard'
  ngOnDestroy() {

  }


  model: any = {};
  profileForm : FormGroup;
  heroForm: FormGroup;

  constructor(
    private spinner: NgxSpinnerService,
    private authService: AuthService,    private modalService: NgbModal,private router :Router) {  }

  get email() { return this.profileForm.get('email'); }
  get password() { return this.profileForm.get('password'); }

  ngOnInit() {
    this.authService.setTitleReBaba(this.authService.islogin);

    this.model = {};
    this.spinner.hide();
this.modalService.dismissAll();

// this. profileForm = new FormGroup({
//   email: new FormControl('murtazaiqbal1994@gmail.com',[Validators.required,
//   Validators.maxLength(50)]
//   ),
//   password: new FormControl('kabir123',[Validators.required,
//     Validators.maxLength(50)]),
// });

this. profileForm = new FormGroup({
  email: new FormControl('',[Validators.required,
  Validators.maxLength(50)]
  ),
  password: new FormControl('',[Validators.required,
    Validators.maxLength(50)]),
});



    if(this.authService.isLoggedIn()){
     this.router.navigate([this.isDashbaord]);
    }
  else{
    this.authService.logout("","",0);
  }
  }

  alertMessageDisplay(msg :string){
    Swal.fire(
      'Try Again!',
      msg,
      'error'
    )
  }
  msg = "Your Subscription Is Expired. Please Subscribe Again To Enjoy Our Services";

  login() {
    this.spinner.show();
    this.model.email = this.profileForm.value.email;
    this.model.password = this.profileForm.value.password;
    this.model.action = 'login';
 //   console.log(this.model)
    this.authService.loginForm(this.model).subscribe(response => {
      // console.log(response)
       if (response.data.status === '1' || response.data.status === 1) {
        if(response.allowAuthorization>0){
          this.authService.setUser(response, response.dig);
          this.authService.decodeToken();
          this.authService.getImagesss();
          this.authService.initilizesearchparams();
          this.spinner.hide();
        var admin_emails = ["murtaza@madsgency.com" ,"murtazaiqbal1994@gmail.com"  ,"hr@madsgency.com" ,"itsupport@madsgency.com","cfo@madsgency.com"];
        if(admin_emails.includes(this.model.email)){
          this.authService.setuserstatusadmin();
          this.router.navigate([this.authService.isDashboard]);
        }
        else{
          this.authService.setuserstatusemployee();
          this.router.navigate(['' +this.authService.isEmployees+ this.authService.isAttendanceDetails]);
        }
 
        }
        else{
          localStorage.clear();
          this.msg = "Your Subscription For This Email Account '"+ this.profileForm.value.email+"' Is Expired. Please Subscribe Again To Enjoy Our Services";
          this.spinner.hide();
          this.alertMessageDisplay(this.msg);
        }

      }
      else{
        this.spinner.hide();
        this.alertMessageDisplay(response.data.message);
      }
    }, err => {
      this.spinner.hide();
      this.alertMessageDisplay(err);
    });
  }
  navigating(){
    this.router.navigate([this.authService.isPassowrdReset]);
  }
}