import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { LoginResponse } from '../Controllers/user';
import { DigVarinfo } from '../Controllers/fetchnotificaiton';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import Swal from 'sweetalert2';
import { ExcelService } from './excel-service.service';
import { Title } from '@angular/platform-browser';
import { SearchSettingMode } from '../Controllers/settings';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  istitleGlob = "QASols HRM Says"
  convertTrueFalse(val) {
    if (val == 1) {
      return true;
    }
    return false;
  }

  validatingValue(val) {
    if (val == true || val == 1 || val == "1" || val == "true") {
      return "Monetization For this video is enabled."
    } else {
      return "Monetization For this video is not enabled."
    }
  }
  updateCounterGoogle() {

  }
  MaxselectVideoCountView = ['10', '20', '50', '100', '200', '300'];//['10', '20', '50', '100', '200', '300'];
  MinselectVideoCountView = ['10', '20', '50', '100', '200', '300'];//['10', '20', '50', '100', '200', '300'];
  getImageHere(data): Observable<LoginResponse> {
    var token =   "Bearer " +this.getUsertokens();
  
    // Define the headers
    const headers = new HttpHeaders({
      //'accept': 'application/json, text/plain, */*',
      //  'access-control-allow-credentials': 'true',
        'Authorization': token,//'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZ2Fkc3VwLmNvbSIsImF1ZCI6Imh0dHBzOlwvXC9xYXNvbHMuY29tIiwiaWF0IjoxNzEyMjUwODA2LCJuYmYiOjE3MTIyNTA4MTYsImV4cCI6MzQyNDU4ODAxMiwiZGF0YSI6eyJHbyI6IjEiLCJlbWFpbFJldCI6ImhyQG1hZHNnZW5jeS5jb20iLCJmbmFtZSI6IkFxc2EgQXJzaGFkIiwibGFzdG5hbWUiOiIiLCJzdGF0dXMiOiIxIiwiZXJyb3IiOiIiLCJtZXNzYWdlIjoiU3VjY2VzcyIsImFsbG93TG9naW4iOjEsImtleSI6Im1rSjZkMDdWSnpsWWFhaDJCTkZpUCJ9fQ.NEWXGM-dNLfkgXSF--JMXEe2bfmgRnaBShbGtJXRk9U',
      // 'content-type': 'multipart/form-data',
    
    });
    return this.http
      .post<LoginResponse>(this.basePath + 'api.php', data, {headers})
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  postImage(dat) {
    var token =   "Bearer " +this.getUsertokens();
  
    // Define the headers
    const headers = new HttpHeaders({
      //'accept': 'application/json, text/plain, */*',
      //  'access-control-allow-credentials': 'true',
        'Authorization': token,//'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZ2Fkc3VwLmNvbSIsImF1ZCI6Imh0dHBzOlwvXC9xYXNvbHMuY29tIiwiaWF0IjoxNzEyMjUwODA2LCJuYmYiOjE3MTIyNTA4MTYsImV4cCI6MzQyNDU4ODAxMiwiZGF0YSI6eyJHbyI6IjEiLCJlbWFpbFJldCI6ImhyQG1hZHNnZW5jeS5jb20iLCJmbmFtZSI6IkFxc2EgQXJzaGFkIiwibGFzdG5hbWUiOiIiLCJzdGF0dXMiOiIxIiwiZXJyb3IiOiIiLCJtZXNzYWdlIjoiU3VjY2VzcyIsImFsbG93TG9naW4iOjEsImtleSI6Im1rSjZkMDdWSnpsWWFhaDJCTkZpUCJ9fQ.NEWXGM-dNLfkgXSF--JMXEe2bfmgRnaBShbGtJXRk9U',
      // 'content-type': 'multipart/form-data',
    
    });
   
    
    // Make the HTTP request
   return  this.http.post(this.basePath+"api.php", dat,{headers})
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  

  ;

  subscription: Subscription;
  subscriptionRenews: string = "Your Subscription is expired. Please re-Subscribe To Use Our Services";
  ngOnDestroy() {
  }
  opts = [];

  getDatas(datas) {
    return this.opts.length ?
      of(this.opts) :
      this.http.post<any>(this.basePath + 'keyword.php', datas, this.httpOptions).pipe(tap(data => this.opts = data))
  }



  keywordSuggestions(data, value): Observable<any> {
    data.keyword = value;
    return this.http
      .post<any>(this.basePath + 'keyword.php', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  basePath = 'https://hrm.qawebsolutions.com/hrms/';
  websitePath = 'https://gadsup.com/'
  // API path
  public isDashboard = "/Employees/Attendance";
  public isGads = "/GAds-Up";
  public isHRM = "/HRM";
  public isNewEmployees: string="/New-Employee";
  public isAllEmployeesAttendanceDetails: string="/All-Attendance-Details";
  public isNotification = "/Notifications-List";
  public isSearchedKeyword = "/Keyword-Searched"
  public isNotificationSingle = "/Notification";
  public islogin = '/Login';
  public isAttendance = '/Attendance';
  public isYoutube = '/Youtube-Videos-Search'
  public isChrome = '/Chrome-Extension'
  public isSmartURLScrapper = '/Smart-URL-Scrapper'
  public isBookmarkComingSoon = "/Extension-Bookmark-Ads"
  public isSearchByKeyword = "/Videos-Search-By-Keywords"
  public isGoogleAdsComingSoon = "/Smart-URL-Scraper-Ad"
  public isFeedBack = "/Customer-FeedBack"
  public isSearchByChannel = "/Videos-Search-By-Channels"
  public isTopChartsVideos = "/TopCharts-Videos"
  public isRelatedVideos = "/Related-Videos"
  public isTrendingVideos = "/Trending-Videos"
  public isChannelSearch = "/Attendance-View"
  public isVideoInfo = "/Youtube-Video-Details"
  public isYoutubeChannel = "/Youtube-Channel";
  public isEmployees = "/Employees";
  public isAttendanceDetails = "/Attendance-Details";
  public isYoutubeChannelDetails = "/Channel-Details";
  public isChannelInfo = "/Search-Featured-Channel"
  public isFeaturedChannel = "/Search-Featured-Channel"
  public isVideoPlacementLList = "/Youtube-Placement-List"
  public isGoogleSearchList = "/Smart-URL-Search"
  public isGoogleSaveList = "/Placement-List"
  public isExtensionBookmarkAdsView = "/Extension-Bookmark-Ads-View"
  public isExtensionBookmarkVideosView = "/Extension-Bookmark-Videos-View"
  public isSavedVideoList = "/Saved-VideosList"
  public isAppSettings = "/App-Settings";
  public isPlacmentListdetails = "/Youtube-Placement-List-Details";
  public isGooglePlacmentListdetails = "/Smart-URL-Placement-List-Details";
  public TabTitle = "~HRM";
  public isPassowrdReset = '/Password-Reset';
  public isPassowrdResetReq = "/Password-Reset-Request";
  constructor(
    private router: Router,
    private http: HttpClient,
    private decoder: JwtHelperService,
    private titleService: Title
  ) {

   this. httpOptionsReinitilize();
  }
  modal: any = {};

  initilizesearchparams() {
    this.modal.code = this.getUserKey();
    this.modal.email = this.getUserEmail();
    this.modal.action = "getusersetting";
    try {
      this.setCurrentUserList(this.modal).subscribe(
        (data) => {
          try {
            if (data.entry > 0) {
              let settings = JSON.parse(data.data)[0];
              SearchSettingMode.searchMode = settings.searchMode;// this.videosearchform.get("searchMode").value;
              SearchSettingMode.publishedbefore = settings.publishedbefore//this.videosearchform.get("publishedbefore").value;
              SearchSettingMode.publishedafter = settings.publishedafter;//this.videosearchform.get("publishedafter").value;
              SearchSettingMode.lisense = settings.lisense;//this.videosearchform.get("lisense").value;
              SearchSettingMode.language = settings.language;// this.videosearchform.get("language").value;
              SearchSettingMode.videoDuration = settings.videoDuration;//this.videosearchform.get("videoDuration").value;
              SearchSettingMode.searchPriority = settings.searchPriority;//this.videosearchform.get("searchPriority").value;
              SearchSettingMode.videoDefination = settings.videoDefination;//this.videosearchform.get("videoDefination").value;
            }
            // this.spinner.hide();
          }
          catch (e) {
            // this.spinner.hide();
          }
        },
        (err) => {
          // this.spinner.hide();
        },
        () => {
          // this.spinner.hide();
        }
      )
    }
    catch (e) {
      // this.spinner.hide();
    }

  }

  setTitleReBaba(info) {
    this.titleService.setTitle(info.substr(1) + this.TabTitle);
  }
  private export_Excel: ExcelService = new ExcelService();
  ExportToExcel(data: any, title) {
    this.export_Excel.exportAsExcelFile(data, title);
  }
  exportTitle = '';
  MessageTitle = "HRM Says,"
  ErrorMessageForExport = 'There Are No Items In The List To Export'

  ExportCompleteDataToExcel(ELEMENT_DATA) {

    if (ELEMENT_DATA.length > 0) {
      this.ExportToExcel(ELEMENT_DATA, this.exportTitle);
    } else {
      this.alertMessageDisplay(this.ErrorMessageForExport, 'error');
    }
  }

  ExportCompleteDataToExcelWithCustomName(ELEMENT_DATA,Title) {
    if (Object.keys(ELEMENT_DATA ).length> 0) {
      this.ExportToExcel((ELEMENT_DATA), this.exportTitle + "_" + Title);
    } else {
      this.alertMessageDisplay(this.ErrorMessageForExport, 'error');
    }
    
  }

  ExportCompleteDataToExcelWithTitle(ELEMENT_DATA, Title) {
    if (ELEMENT_DATA.length > 0) {
      this.ExportToExcel(ELEMENT_DATA, Title);
    } else {
      this.alertMessageDisplay(this.ErrorMessageForExport, 'error');
    }
    
  }
  public loadScript() {
    const node = document.createElement('script');
    node.src = 'assets/dist/js/demo.js'; // put there your js file location
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  ExportCompleteDataToJSON(ELEMENT_DATA) {
    if (ELEMENT_DATA.length > 0) {
      this.ExportToJSON(ELEMENT_DATA, this.exportTitle);
    } else {
      this.alertMessageDisplay(this.ErrorMessageForExport, 'error');
    }
  }

  ExportCompleteDataToJSONWithTitle(ELEMENT_DATA, Title) {
    if (ELEMENT_DATA.length > 0) {
      this.ExportToJSON(ELEMENT_DATA, this.exportTitle + "_" + Title);
    } else {
      this.alertMessageDisplay(this.ErrorMessageForExport, 'error');
    }
  }

  ExportCompleteDataToCSV(ELEMENT_DATA) {
    if (ELEMENT_DATA.length > 0) {
      let data: string = '';
      data +=
        'thumbnail|videoid|title|channelTitle|channelId|defaultAudioLanguage|tags|description|publishedAt|viewCount|likeCount|dislikeCount|favoriteCount|commentCount|privacyStatus|license|duration|licensedContent|monitization' +
        '\n';
      for (var i = 0; i < ELEMENT_DATA.length; i++) {
        data +=
          ELEMENT_DATA[i].thumbnail +
          '|' +
          ELEMENT_DATA[i].videoid +
          '|' +
          ELEMENT_DATA[i].title +
          '|' +
          ELEMENT_DATA[i].channelTitle +
          '|' +
          ELEMENT_DATA[i].channelId +
          '|' +
          ELEMENT_DATA[i].defaultAudioLanguage +
          '|' +
          ELEMENT_DATA[i].tags +
          '|' +
          ELEMENT_DATA[i].description +
          '|' +
          ELEMENT_DATA[i].publishedAt +
          '|' +
          ELEMENT_DATA[i].viewCount +
          '|' +
          ELEMENT_DATA[i].likeCount +
          '|' +
          ELEMENT_DATA[i].dislikeCount +
          '|' +
          ELEMENT_DATA[i].favoriteCount +
          '|' +
          ELEMENT_DATA[i].commentCount +
          '|' +
          ELEMENT_DATA[i].privacyStatus +
          '|' +
          ELEMENT_DATA[i].license +
          '|' +
          ELEMENT_DATA[i].duration +
          '|' +
          ELEMENT_DATA[i].licensedContent +
          '|' +
          ELEMENT_DATA[i].monitization +
          '\n';
      }
      this.ExportToCSV(data, this.exportTitle);
    } else {
      this.alertMessageDisplay(this.ErrorMessageForExport, 'error');
    }
  }
  ExportCompleteDataToCSVWithTitle(ELEMENT_DATA, Title) {
    if (ELEMENT_DATA.length > 0) {
      let data: string = '';
      data +=
        'thumbnail|videoid|title|channelTitle|channelId|defaultAudioLanguage|tags|description|publishedAt|viewCount|likeCount|dislikeCount|favoriteCount|commentCount|privacyStatus|license|duration|licensedContent|monitization' +
        '\n';
      for (var i = 0; i < ELEMENT_DATA.length; i++) {
        data +=
          ELEMENT_DATA[i].thumbnail +
          '|' +
          ELEMENT_DATA[i].videoid +
          '|' +
          ELEMENT_DATA[i].title +
          '|' +
          ELEMENT_DATA[i].channelTitle +
          '|' +
          ELEMENT_DATA[i].channelId +
          '|' +
          ELEMENT_DATA[i].defaultAudioLanguage +
          '|' +
          ELEMENT_DATA[i].tags +
          '|' +
          ELEMENT_DATA[i].description +
          '|' +
          ELEMENT_DATA[i].publishedAt +
          '|' +
          ELEMENT_DATA[i].viewCount +
          '|' +
          ELEMENT_DATA[i].likeCount +
          '|' +
          ELEMENT_DATA[i].dislikeCount +
          '|' +
          ELEMENT_DATA[i].favoriteCount +
          '|' +
          ELEMENT_DATA[i].commentCount +
          '|' +
          ELEMENT_DATA[i].privacyStatus +
          '|' +
          ELEMENT_DATA[i].license +
          '|' +
          ELEMENT_DATA[i].duration +
          '|' +
          ELEMENT_DATA[i].licensedContent +
          '|' +
          ELEMENT_DATA[i].monitization +
          '\n';
      }
      this.ExportToCSV(data, this.exportTitle + "_" + Title);
    } else {
      this.alertMessageDisplay(this.ErrorMessageForExport, 'error');
    }
  }





  ExportToJSON(data: any, title) {
    this.export_Excel.saveAsJSONFile(data, title);
  }
  ExportToCSV(data: any, title) {
    this.export_Excel.saveAsCSVFile(data, title);
  }
  validate() {
    if (!this.isLoggedIn() || this.decoder.isTokenExpired(this.getUsertokens())) {
      this.router.navigate([this.islogin]).then((E) => {
        localStorage.clear();
        this.alertMessageDisplay("Your Session Is Expired. Please Login In Again", "error");
      });
      return false;
    } else {
      return true;
    }
  }

  validateUserToNavigate(divert) {
    if (this.isLoggedIn() || this.decoder.isTokenExpired(this.getUsertokens())) {
      this.router.navigate([divert]).then((E) => {
        // localStorage.clear();
        //this.alertMessageDisplay("Your Session Is Expired. Please Login In Again","error");
      });
    } else {
      this.logout(this.msg, "error", 1);
    }
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
      // 'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    })
  };

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    let msg = "";
    console.log(error)
    if (error.status == 402) {
      msg =
        'Invalid Request Recieved. Try Again';
    }
    else if (error.status == 404) {
      // A client-side or network error occurred. Handle it accordingly.
      // return an observable with a user-facing error message
      msg =
        'Request Denied. Try Again';
    }
    else if (error.status == 401) {
      msg = 'Token Expired'
    }
    else {
      msg = 'Unknown Error. Try Again'
    }
    return throwError(
      msg);

  }

  getUserDate() {
    return "2020 November, 13";//localStorage.getItem("datesince");
  }
  rawImage = "";
  rawImageCode = "";

  getUserImage() {
    let val = localStorage.getItem("image");
    // // //console.log(val)
    let img = "";
    if (val == null || val == undefined || val.toString().length <= 0) {
      img = ' <img class="myimage" src="../../assets/dist/img/avatar.png" class="img-circle" alt="User Image">';

    }
    else {
      img = ' <img class="myimage" src="data:image/png;base64,' + val + '" class="img-circle" alt="User Image">';

    }
    // // //console.log(img);
    this.rawImage = img;
    return this.rawImage;
  }

  setlocalstorageimage(val) {
    // //console.log(val)
    if (val == null || val == undefined || val.toString().length <= 0) {
      val = "*";
    }
    localStorage.setItem("image", val);
  }
  getRawImage() {
    let val = localStorage.getItem("image");
    // // //console.log(val)
    if (val == null || val == "*" || val == " " || val == undefined || val.toString().length <= 0) {
      this.rawImageCode = '../../assets/dist/img/avatar.png';
    }
    else {
      this.rawImageCode =   val
    }
    return this.rawImageCode;
  }
  getUserName() {
    return localStorage.getItem("username");
  }
  getusertypestatus() {
    return localStorage.getItem("usertypestatus");
  }
  setuserstatusadmin() {
    localStorage.setItem("usertypestatus", "Admin");
  }
  setuserstatusemployee() {
    localStorage.setItem("usertypestatus", "Employee");
  }
  getUserNameExists() {
    return localStorage.getItem("username") != null;
  }

  getUserEmailExists() {
    return localStorage.getItem("email") != null;
  }

  getUserEmail() {
    return localStorage.getItem("email");
  }

  getUserKeyExists() {
    return localStorage.getItem("superdupersecretkey") != null;
  }
  
  ImageKeyExists() {
    return localStorage.getItem("image") != null;
  }
  getUserKey() {

    return localStorage.getItem("superdupersecretkey");
  }

  getUsertokenExists() {
    return localStorage.getItem("access_token") != null;
  }
  getemployeeidExists() {
    return localStorage.getItem("employeeid") != null;
  }
  getUsertokens() {
    return localStorage.getItem("access_token");

  }

   

  isLoggedIn() {
    return this.getUsertokenExists()    
    }
 

  loginForm(data): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(this.basePath + 'api.php', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

 

  // Verify user credentials on server to get token
  getCurrentUserInfo(data): Observable<any> {
    return this.http
      .post<any>(this.basePath + 'api.php', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  setCurrentUserList(data): Observable<any> {
    return this.http
      .post<any>(this.basePath + 'api.php', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updategooglecounter(data): Observable<any> {
    return this.http
      .post<any>(this.basePath + 'api.php', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getuserkeywordsInfo(data): Observable<any> {
    return this.http
      .post<any>(this.basePath + 'api.php', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteCurrentUserList(data): Observable<any> {
    return this.http
      .post<any>(this.basePath + 'api.php', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }


  getEmployeesData(data): Observable<any> {
    return this.http
      .post<any>(this.basePath + 'api.php', data, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
  getEmployeesDataAttendance(data): Observable<any> {
    return this.http
      .post<any>(this.basePath + 'api.php', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCurrentUserList(data): Observable<any> {
    return this.http
      .post<any>(this.basePath + 'api.php', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  resetUserPassword(data): Observable<any> {
    return this.http
      .post<any>(this.basePath + 'api.php', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  paginatorValues = [10, 50, 100]
  models: any = {};
  getImagesss() {
    this.models.action = "getimage";
    this.models.email = this.getUserEmail();
    this.models.code = this.getUserKey();
     this.getImageHere(this.models).subscribe((data) => {
      console.log("Image data",data);
      /*
      data: "uploads/619a340b0383b.jpg"
      status: "success"
      */
      var path = data["data"];
      if (path != "*" && path.length > 0) {
        var url = "https://hrm.qawebsolutions.com/hrms/uploads/" + data["data"];
   
              this.setlocalstorageimage(url);
              this.getRawImage(); this.getUserImage();

          
      }
      else {
        path = "*";
        this.setlocalstorageimage("");
        this.getRawImage(); this.getUserImage();

      }
      // // //console.log(data);
    });
  }

  videoKeywordlocalitems = "Videokeywordlocaldata";
  cheannelvideolocalitems = "channelVideolocaldata";
  TopChartsvideolocalitems = "TopchartsVideolocaldata";
  VideoRelatedlocalitems = "VideoRelatedlocalitems";
  Trendinglocalitems = "Trendinglocalitems";
  Channellocalitems = "Channellocalitems";
  FeaturedChannellocalitems = "FeaturedChannellocalitems";
  setLocalstorageitem(itemname, data) {
    localStorage.setItem(itemname, data);
  }
  localstorageitemcheck(itemname) {
    return localStorage.getItem(itemname) != null;
  }

  getlocalstorageitem(itemname) {
    return localStorage.getItem(itemname);
  }

  getlocalstorageitemEmployeeid() {
    return localStorage.getItem("employeeid");
  }


  setUser(resp: LoginResponse, dig) {

    localStorage.setItem('username', resp.data.fname + ' ' + resp.data.lastname);
    localStorage.setItem('email', resp.data.emailRet);
    localStorage.setItem('access_token', resp.access_token);
    localStorage.setItem('superdupersecretkey', resp.data.key);
    localStorage.setItem('dig', dig);
    localStorage.setItem("employeeid",resp.info[0].employee_id);
    // localStorage.setItem('axes',axes);
    // localStorage.setItem('yaxes',yaxes);
    this.router.navigate([this.isDashboard]);
  }

  validateUserTokenExists() {
    if (!(this.getUsertokenExists() || this.getUserKeyExists() || this.getUserEmailExists() || this.getUserNameExists()) || this.isSessionExpired()) {
      this.logout(this.msg, "error", 1);

    }
  }

  isSessionExpired() {
    try {
      let token: string = localStorage.getItem("access_token");
      if (this.decoder.isTokenExpired(token)) {
        this.logout(this.msg, "error", 1);

        return true;
      }
      else {
        return false;
      }
    }
    catch (err) {
      this.logout(this.msg, "error", 1);
      return true;
    }
  }

  isSessionExpiredTimer() {
    let token: string = localStorage.getItem("access_token");
    if (!(this.getUsertokenExists() || this.getUserKeyExists() || this.getUserEmailExists() || this.getUserNameExists()) || this.decoder.isTokenExpired(token)) {
      return true;
    }

    return false;
  }

  decodeToken() {
    let token: string = localStorage.getItem("access_token");
    return token;
  }

  httpOptionsReinitilize(){
    var token =   "Bearer " +this.getUsertokens();
   // console.log("Token",token)
   this.httpOptions.headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Authorization': token,
   });
 
  }
  

  validateKey(data) {
    this.httpOptionsReinitilize();
    return this.http
      .post<any>(this.basePath + 'validateuser.php', data, this.httpOptions)
      .pipe(
        retry(1),
        // catchError()
      );
  }
  msg = "Your Session Is Expired. Please Log In";
  verifyfromdatabase() {
    var data = { email: this.getUserEmail(), key: this.getUserKey(), action: "Validation From Server Side" }
     
    this.subscription = this.validateKey(data).subscribe(response => {
      console.log(response)
      localStorage.setItem('dig', response.dig);
      DigVarinfo.Dig = parseInt(response.dig);
      let type = "error";
      let msg = this.msg; 
      if (response.allowAuthorization <= 0) {
        msg = "Your Subscription Is Expired!. Please Subscribe Again To Enjoy Our Services";
        type = "info";
      }

      if (response.data < 1 || response.data == "0") {
        this.logout(this.msg, type, 1);
      }
    }, (err) => {
      this.logout(this.msg, "error", 1);
    });
  }




  updatelogouttodatabase(data) {
    try {
      this.subscription = this.validateKey(data).subscribe(response => {
        // // //console.log(response);
        // // //console.log("hanji");
      }, (err) => {
        // // //console.log(err);
        // this.logout();
      });
    } catch (err) {
    }
  }

  logout(msg, type, allow) {
    var data = { email: this.getUserEmail(), jwt: this.getUsertokens(), key: this.getUserKey(), action: "Validation  . . . .  ." }
    this.updatelogouttodatabase(data);
    localStorage.clear();
    this.router.navigateByUrl(this.islogin).then((e) => {
      if (allow == 1) {
        this.alertMessageDisplay(msg, type)
      }
    });
  }

  alertMessageDisplay(msg: string, type: any) {
    Swal.fire(
      this.MessageTitle,
      msg,
      type
    );
  }
  // Get data from server for Dashboard
  getData(data): Observable<LoginResponse> {

    return this.http
      .post<LoginResponse>(this.basePath + 'api.php', data, this.httpOptions)
      .pipe(

      );
  }
}
