<ngx-spinner 
  style="position: relative;right:30%"  bdColor ="rgba(0,0,0,0.5)" size = "medium" color = "white" type = "timer" [fullScreen]= "true"><p style="color: white" >  </p></ngx-spinner>

<div class="login-register"
  style="background:url('../../../assets/images/BG.jpg');background-size: cover; background-position: center;">
  <div class="login-box card" style="border-radius: 22px;">
    <div class="card-body">
   
        <form [formGroup]="profileForm"  #formDir="ngForm" class="login-form form-validate"   (ngSubmit)="login()" >
          <p class="text-center">
            <img src="https://qasols.com/wp-content/uploads/2021/10/OFFICAL-LOGO.png" style="width:280px" alt="">
          </p> <br>
          <h3 class="box-title m-b-20 text-center">HRM
            <br>
            <small>
              Login to your account
            </small>
          </h3>
          
          <div class="form-group has-feedback">
              <input type="email" class="form-control"  pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" title="Email Is Required"  id="email" formControlName="email" required  placeholder="Email" >
              <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
              <div *ngIf="email.invalid && (email.dirty || email.touched)"
              class="alert alert-danger" style="padding: 5px;">
              <div *ngIf="email.errors.required">
                Email is required. <i class="icon-warning22"></i>
              </div>
              <div *ngIf="email.errors.pattern">
                Provided text does not match email format.
              </div>
            </div>
          </div>
  
  
  
          <div class="col-xs-12">
            <button [disabled]="!profileForm.valid" type="submit" class="btn btn-my btn-lg btn-block text-uppercase waves-effect waves-light" >
              Request For The Password <i class="icon-circle-right2 ml-2"></i>
            </button>
          </div>
          <br>
          <div class="form-group m-b-0">
            <div class="col-sm-12 text-center">
              <p class="text-center "> <a (click)="navigating()" id="to-recover"  style="color:#285CA9;cursor: pointer">
                <i class="fa fa-lock m-r-5"></i> <b>Sign In</b> </a></p>
              <div>By continuing, you're confirming that you've read our Terms & Conditions and Cookie Policy
  
              </div>
              <br>
            </div>
          </div>
        </form>
  

    </div>
  </div>
</div>