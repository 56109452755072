import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import Swal from 'sweetalert2';
import { OnInit } from '@angular/core';
import { SearchSettingMode } from './Controllers/settings';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit  {
  title = 'app';
  
  constructor(public authService : AuthService){
  }
  public captchaResponse: string = '';
  public resolved(captchaResponse: string) {
    const newResponse = captchaResponse
      ? `${captchaResponse.substr(0, 7)}...${captchaResponse.substr(-7)}`
      : captchaResponse;
    this.captchaResponse += `${JSON.stringify(newResponse)}\n`;
  }
  modal: any = {};
  

  ngOnInit(){
 
      this.authService.initilizesearchparams();//(this.modal).subscribe(
      
  }


}
