<ngx-spinner 
  style="position: relative;right:30%"  bdColor ="rgba(0,0,0,0.5)" size = "medium" color = "white" type = "timer" [fullScreen]= "true"><p style="color: white" >  </p></ngx-spinner>

<div class="login-register"
  style="background:url('../../../assets/images/BG.jpg');background-size: cover; background-position: center;">
  <div class="login-box card" style="border-radius: 22px;">
    <div class="card-body">
   
      <form *ngIf="this.show" [formGroup]="heroForm" (ngSubmit)="login()" class="login-form form-validate"   #formDir="ngForm">

        <p class="text-center">
          <img src="https://qasols.com/wp-content/uploads/2021/10/OFFICAL-LOGO.png" style="width:280px" alt="">
        </p> <br>
        <h3 class="box-title m-b-20 text-center">Reset  Password Form
         
        </h3>

        <div class="form-group has-feedback">
          <input [disabled] type="email" class="form-control"  pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" title="Email Is Required"  id="email" formControlName="email" required  placeholder="Email" >
          <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
          <div *ngIf="email.invalid && (email.dirty || email.touched)"
          class="alert alert-danger" style="padding: 5px;">
          <div *ngIf="email.errors.required">
            Email is required. <i class="icon-warning22"></i>
          </div>
          <div *ngIf="email.errors.pattern">
            Provided text does not match email format.
          </div>
        </div>
      </div>


      <div class="form-group has-feedback">
        <label for="">    New Password </label>
        <input [disabled]   pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"  type="password" placeholder="Enter New Password" formControlName="Password" name="passwordFieldName"  class="form-control">
        <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
        <div *ngIf="Password.invalid && (Password.dirty || Password.touched)"      class="alert alert-danger">

         <p *ngIf="Password.errors.required">
           New Password is required.
         </p>
         <p *ngIf="Password.errors.minlength">
           New Password must be at least 8 characters long.
         </p>
         <p *ngIf="Password.errors.maxlength">
          Password Can Only be length of 50 ;
        </p>

          <p *ngIf="Password.errors.pattern">
            Password Does Not Match The Describe pattren:
            Description :  lower case letters,  upper case letter,  digits, 8-50 length and special charaters are also allowed.
          </p>
         </div>

       </div>


    <div class="form-group has-feedback">
        <label for="">   Conform Password </label>
        <input  ng-maxlength="50" type="password" placeholder="Confirm Password" formControlName="conformPassword" class="form-control" >
        <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
        <div *ngIf="conformPassword.invalid && (conformPassword.dirty || conformPassword.touched)"      class="alert alert-danger">

         <p *ngIf="conformPassword.errors.required">
           Confromed Password is required.
         </p>


         <p *ngIf="conformPassword.errors.mustMatch==true">
            Password Does Not Match.!
         </p>

         <p *ngIf="conformPassword.errors.maxlength">
           Password Can Only be length of 50 ;                        </p>

         </div>

       </div>


      <div class="col-xs-12">
        <button [disabled]="!heroForm.valid" type="submit" class="btn btn-my btn-lg btn-block text-uppercase waves-effect waves-light" >
          Sign in <i class="icon-circle-right2 ml-2"></i>
        </button>
      </div>

      <br>
      <div class="form-group m-b-0">
        <div class="col-sm-12 text-center">
          <p class="text-center "> <a (click)="navigating()" id="to-recover"  style="color:#285CA9;cursor: pointer">
            <i class="fa fa-lock m-r-5"></i> <b>Sign In</b> </a></p>
          <div>By continuing, you're confirming that you've read our Terms & Conditions and Cookie Policy

          </div>
          <br>
        </div>
      </div>
    </form>


    </div>
  </div>
</div>