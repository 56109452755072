<style>
    .topbar ul.dropdown-user li .dw-user-box .u-img img {
    width: 100%;
    border-radius: 50%;
    margin-left: 15px;
    margin-bottom: 13px;
}
    .mini{
    display: none;        
    }
    @media screen and (max-width: 1168px) {
        .topbar ul.dropdown-user li .dw-user-box .u-img img {
    width: 80%;
    border-radius: 50%;
    margin-left: 15px;
    margin-bottom: 13px;
}
.dropdown a{
    padding-left: 30px;
}
        .mini{
    display: inline-block;        
    }
   }
   .mailbox .message-center {
    height: auto !important;
    overflow: auto;
    position: relative;
    max-height: 180px;
}
.label-warning{
padding: 3px 9px;
    line-height: 13px;
    color: #ffffff;
    font-weight: 400;
    border-radius: 4px;
    font-size: 55%;
    position: absolute;
    left: 25px;}

  
</style>

<header class="topbar">
    <nav class="d-flex top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="#/Dashboard">
                <!-- Logo icon -->
                <b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <!-- <img src="assets/images/short.png" style="width:50px;margin-top: 0px;" alt="homepage" class="dark-logo" /> -->
                    <!-- Light Logo icon -->
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span>
                    <!-- dark Logo text -->
                    <!-- <img src="assets/images/128.png" alt="homepage" class="dark-logo" /> -->
                    <!-- Light Logo text -->
                    <img 
                     src="https://madsgency.s3.amazonaws.com/wp-content/uploads/2023/03/30073717/Madsgency-Logo-02-1536x493.png"
                    style="width:220px;margin-top: 10px;" alt="homepage" class="dark-logo" />                </span>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto mt-md-0">
                <!-- This is  -->
                <li (click)="toggleSidebar.emit()" class="nav-item m-l-10">
                    <a class="nav-link sidebartoggler waves-effect waves-dark" href="javascript:void(0)"><i
                            class="ti-menu"></i></a>
                </li>
                <!-- ============================================================== -->
                <!-- Comment -->
                <!-- ============================================================== -->
                <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-left">
                    <a ngbDropdownToggle class="nav-link text-muted text-muted waves-effect waves-dark"
                        href="javascript:void(0)" href="https://supportcenter.madsgency.com/support/home">
                        <i class="mdi mdi-message"></i>
                        <div class="notify">
                            <span class="heartbit"></span>
                            <span class="point"></span>
                        </div>
                    </a>

                </li> -->
                <!-- ============================================================== -->
                <!-- End Comment -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Messages -->
                <!-- ============================================================== -->
                <!-- <li class="nav-item dropdown" (click)="dada_tiyagi()" ngbDropdown placement="bottom-left">
                    <span *ngIf="this.falg" class="label label-warning">{{this.numberOfNotifications}}
                    </span>
                    <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark" href="javascript:void(0)">
                        <i class="mdi mdi-bell-ring"></i>
                        <div class="notify">
                            <span class="heartbit"></span>
                            <span class="point"></span>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-left mailbox" ngbDropdownMenu>
                        <ul>

                            <li *ngIf="this.flash">
                                <a class="nav-link text-center" href="javascript:void(0);">
                                    <strong>You have {{this.numberOfNotifications}} notifications</strong>
                                    <i class="fa fa-angle-right"></i>
                                </a>
                            </li>
 

                            <li>
                                <div class="drop-title">Notifications</div>
                            </li>
                            <li>
                                <div class="message-center" [perfectScrollbar]="config">
                                    <a  *ngFor="let item of this.datadisposal" (click)="navigateing(item.id)">
                                        <div class="user-img">
                                            <img src="../../../assets/images/128.png" alt="user" class="img-circle"
                                                width="40">
                                         </div>
                                        <div class="mail-contnet">
                                            <h5>{{ displayadahaadhura(item.title)}}</h5>
                                            <span class="time">{{ displayadahaadhura(item.AddedBy)}}</span> 
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li *ngIf="!this.falg">
                                <ul class="menu">
                                  <li *ngIf="!this.flash">
                                    
                                   
                                    <div class="mail-contnet">
                                        <h5 style="margin-top: 2px; padding: 10px"> <i class="fa fa-users"></i> no new notification yet</h5>
                                       
                                    </div>
                                  </li>
                                </ul>
                              </li>

                            <li>
                                <a (click)="this.navigateingToNot()" class="nav-link text-center" href="javascript:void(0);">
                                    <strong>See all Notifications</strong>
                                     &nbsp;<i class="fa fa-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li> -->
                <!-- <li (click)="dada_tiyagi()" class="dropdown notifications-menu">
                    <ul class="dropdown-menu">
          
                      <li *ngIf="this.flash" class="header">You have {{this.numberOfNotifications}} notifications</li>
                      <li *ngFor="let item of this.datadisposal"><a (click)="navigateing(item.id)">
                          <i class="fa fa-envelope-o text-aqua"></i>
                          {{ displayadahaadhura(item.detail)}}</a></li>
          
          
          
                      <li *ngIf="!this.falg">
                        <ul class="menu">
                          <li *ngIf="!this.flash">
                            <a >
                             &nbsp;&nbsp; <i class="fa fa-users "></i> no new notification yet
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li class="footer"><a (click)="this.navigateingToNot()">View all</a></li>
                    </ul>
                  </li> -->
                <!-- ============================================================== -->
                <!-- End Messages -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Messages -->
                <!-- ============================================================== -->

                <!-- ============================================================== -->
                <!-- End Messages -->
                <!-- ============================================================== -->
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <!-- ============================================================== -->
                <!-- Search -->
                <!-- ============================================================== -->
                <li class="nav-item hidden-sm-down search-box">
                    <!-- <a class="nav-link hidden-sm-down text-muted waves-effect waves-dark" href="javascript:void(0)">
                        <i class="ti-search"></i>
                    </a> -->
                    <form class="app-search">
                        <input type="text" class="form-control" placeholder="Search & enter">
                        <a class="srh-btn">
                            <i class="ti-close"></i>
                        </a>
                    </form>
                </li>
                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                        href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                         <img src="assets/images/avatar1.png" class="profile-pic" alt="User Image">

                    </a>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-img">
                                         <img src="assets/dist/img/avatar.png" class="profile-pic" alt="User Image">

                                    </div>
                                    <div class="u-text">
                                        <h4> {{ username }}</h4>
                                        <small>Member since {{ date }}</small>
                                        <p class="text-muted"> {{ email }}</p>
                                        <!-- <a href="pages-profile.html" class="btn btn-rounded btn-danger btn-sm">View
                                            Profile</a> -->
                                    </div>
                                </div>
                            </li>
                            <!-- <li role="separator" class="divider"></li> -->
                            <!-- <li>
                                <a href="#">
                                    <i class="ti-user"></i> My Profile</a>
                            </li> -->


                            <li role="separator" class="divider"></li>
                            <!-- <li>
                                <a (click)=" navigateingToSettings()">
                                    <i class="ti-settings"></i> Account Setting</a>
                            </li> -->
                            <li role="separator" class="divider"></li>
                            <li>
                                <a  (click)="logout()" >
                                    <i class="fa fa-power-off"></i> Logout</a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
