import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { LoginComponent } from './authentication/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { NotFoundComponent } from './authentication/404/not-found.component';
import { EmployeesModules } from './employees/extension-bookmark.module';
import { PasswordResetComponent } from './authentication/password-reset/password-reset.component';
import { PasswordResetRequestComponent } from './authentication/password-reset-request/password-reset-request.component';
 
export const routes: Routes = [
    {path:'' , redirectTo: '/Login', pathMatch:'full'},
    {
        path: 'Login',
         component: LoginComponent
         ,data:{title:'Login - HRM'}
    },   
    {
        path: 'Password-Reset',
         component: PasswordResetComponent
         ,data:{title:'Password Reset - HRM'}
    },
    {
        path: 'Password-Reset-Request',
         component: PasswordResetRequestComponent
         ,data:{title:'Password Reset - HRM'}
    },
    {
        path: '',
        component: FullComponent,
        children: [
            { path: '', redirectTo: '/Dashboard', pathMatch: 'full' },
            {
                path: 'Dashboard',
                canActivate:[AuthGuard],
                loadChildren: () => import('./dashboards/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'Employees',
                canActivate:[AuthGuard],
                loadChildren: () => import('./employees/extension-bookmark.module').then(m => m.EmployeesModules)
            },
            // {
            //     path: 'Youtube-Channel',
            //     canActivate:[AuthGuard],
            //     loadChildren: () => import('./channel-search/channel-search.module').then(m => m.ChannelSearchModule)
            // },
            // {
            //     path: 'Chrome-Extension',
            //     canActivate:[AuthGuard],
            //     loadChildren: () => import('./extension-bookmark/extension-bookmark.module').then(m => m.ExtensionBookmarkModules)
            // },
            
            // {
            //     path: 'Smart-URL-Scrapper',
            //     canActivate:[AuthGuard],
            //     loadChildren: () => import('./google-url-scrapper/google-url-scrapper.module').then(m => m.GoogleURLScrapperModule)
            // },
            {
                path: 'HRM-Settings',
                canActivate:[AuthGuard],
                loadChildren: () => import('./others/others.module').then(m => m.OthersModule)
            },
        ]
    },
    {
        path: '',
        component: BlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren:
                    () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
            }
        ]
    },
  
];

@NgModule({
    imports: [RouterModule.forRoot(routes), NgbModule],
    exports: [RouterModule]
})
export class AppRoutingModule { }
