import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthService } from '../../services/auth.service';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  flip = 0;
  toggleImage = "assets/images/281x82-b.png";
  hello(){
    this.toggleSidebar.emit();
    if(this.flip==0){
      // this.toggleImage = "assets/images/128.png";
      this.flip = 1;
    }
    else{
      // this.toggleImage = "assets/images/281x82-b.png";
      this.flip = 0;
    }
  }

  public config: PerfectScrollbarConfigInterface = {};
  constructor(private modalService: NgbModal,
    public authService: AuthService,
     private router : Router ) {}

  // This is for Notifications
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
 public  mymessages: any[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];





  ngAfterViewInit() {}
  numberOfNotifications:number=0;

  validateImage(img){
    if(img=="data:image/png;base64,*")
    {
return "../../assets/dist/img/avatar.png";
    }
    else{
      return img;
    }
  }
  falg:boolean=false;
 
  isDashboard = "/Dashboard"
  username :string = "";
  image :string = "";
  date : string = "";
  modal :any={};
  datadisp :any;
  datadisposal :any=[];
  flash :boolean = true;
dada_tiyagi(){
  this.modal.action = "updatenotifications";
  this.modal.email = this.authService.getUserEmail();
  this.modal.code = this.authService.getUserKey();
  this.modal.list = this.listofids;
  this.authService.getData(this.modal).subscribe((data)=>{
    this.falg=false;
    // //console.log(data);
  },
  (err)=>{
    // //console.log(err);
  });
}

 listofids = "0";
email =""
 displayadahaadhura(val:string){
   return val.substr(0,25);
 }
 navigateing(id){
  this.router.navigate([this.authService.isGads+this.authService.isNotificationSingle], { queryParams: { id: id } });
 }
 navigateingFeedBack(){
  this.router.navigate([''+this.authService.isFeedBack]);
 }

 navigateingToNot(){
  this.router.navigate([''+this.authService.isGads+''+this.authService.isNotification]);
 }

 
 navigateingToSettings(){
  this.router.navigate([''+this.authService.isHRM+''+this.authService.isAppSettings]);
 }
  ngOnInit(): void {
    this.username = this.authService.getUserName();
    this.date = this.authService.getUserDate();
    this.image = this.authService.getRawImage();
    this.flash = false;
    this.email = this.authService.getUserEmail();   
  }
  ngAfterViwInit(){ 
    this.modal.action = "notificationsdisplaygetvalues";
    this.modal.email = this.authService.getUserEmail();
    this.modal.code = this.authService.getUserKey();
    this.authService.getData(this.modal).subscribe((data)=>{
      console.log(data)
      let jsondata = JSON.parse(data.data);
      if(Object.keys(jsondata).length>0){
        this.datadisp = jsondata;
        let countr = 0;
        this.datadisp.forEach(element => {
          if(element.flag==1){
            this.numberOfNotifications++;
            this.falg=true;
            this.listofids += ","+element.id;
            this.flash = true;
            if(countr<=4){
              this.datadisposal.push(element);
              countr++;
            }
            // //console.log(this.datadisposal);
          }
        });
        // //console.log(this.datadisp);
      }
    },
    (err)=>{
      // // //console.log(err);
    })
  }
  logout(){
    this.authService.logout("Your Are Logged Out","info",1);
  }
isAppSetting = "/App-Setting";
  appSettings(){
    this.authService.validateUserToNavigate(this.isAppSetting);
  }


}

