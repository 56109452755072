import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private spinner: NgxSpinnerService,
  ) { }

  videosearchform = new FormGroup({
    searchKeyword: new FormControl('', Validators.required)
  });
  model: any = {};
  messageDisplay(title, msg, type) {
    this.spinner.hide();
    Swal.fire(
      title,
      msg,
      type
    );
  }
  public get searchKeyword() { return this.videosearchform.get('searchKeyword'); }
  searchvideos() {
    this.spinner.show();
    this.authService.verifyfromdatabase();
    this.model.action = 'feedback';
    this.model.email = this.authService.getUserEmail();
    this.model.code = this.authService.getUserKey();
    this.model.text = this.searchKeyword.value;
    this.authService.updategooglecounter(this.model).subscribe((data) => {
      if (data.data > 0) {
        this.videosearchform.reset();
        this.messageDisplay(this.authService.MessageTitle, "Your Feedback is submited. We Appricate your concern. Our Team will look at into it", "success");
      }
      else {
        {
          this.messageDisplay(this.authService.MessageTitle, "Unable to fullfill your request. Try Again Or Contact our support", "info");
        }
      }

      this.spinner.hide();
    },
      (err) => {
        this.spinner.hide();
        this.messageDisplay(this.authService.MessageTitle, "Internal Server Error , Try Again.", "error");

      },
      () => {
        this.spinner.hide();
      }
    )
  }

  ngOnInit(): void {
    this.authService.verifyfromdatabase();
  }



}
