import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { error } from '@angular/compiler/src/util';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
 @Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  model: any = {};
  profileForm : FormGroup;
  heroForm: FormGroup;

  constructor(
    private spinner: NgxSpinnerService,
    private authService: AuthService,    private modalService: NgbModal,private router :Router) {  }

  get email() { return this.profileForm.get('email'); }
  bhagDKBOSS(msg){
    this.spinner.hide();
    Swal.fire({
      title: this.authService. MessageTitle,
      text: msg,
      icon: 'info',
      showCancelButton: false,
      allowOutsideClick:false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK!'
    }).then((result) => {
      this.navigating();
    })
  }



  ngOnInit() {
    this.authService.setTitleReBaba(this.authService.isPassowrdReset);

    this.model = {};
this. profileForm = new FormGroup({
  email: new FormControl('',[Validators.required,
  Validators.maxLength(50)]
  )
});



    if(this.authService.isLoggedIn()){
     this.router.navigate([this.authService.isDashboard]);
    }

  }

  alertMessageDisplay(msg :string,type){
    this.spinner.hide();
    Swal.fire(
      'Try Again!',
      msg,
type    )
  }

  login() {
    this.spinner.show();
    this.model.email = this.profileForm.value.email;
    this.model.action = 'requestResetPassowrd';
    this.authService.loginForm(this.model).subscribe(response => {
      if (response.status === 'success') {
        // // //console.log(response);
       this.profileForm.reset();
       this.bhagDKBOSS(response.data);
      }
      else{
        this.spinner.hide();
        this.alertMessageDisplay(response.data,"error");
      }


    }, err => {
      this.spinner.hide();
      this.alertMessageDisplay("Invalid Error, Please Contact Our Support Team","error");
    });
  }
  navigating(){
    this.router.navigate([this.authService.islogin]);
  }
}
