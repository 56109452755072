import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {    CommonModule,    LocationStrategy,    HashLocationStrategy} from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr'; 
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
 import { FeedbackComponent } from './shared/feedback/feedback.component';
 import { ChartsModule } from 'ng2-charts';
import {MatIconModule} from '@angular/material/icon';


  const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
        FullComponent,
        BlankComponent,
        NavigationComponent,
        BreadcrumbComponent,
        SidebarComponent,
        FeedbackComponent,
     ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule
,
        FormsModule,
        HttpClientModule, MatTableModule,MatIconModule,
        RecaptchaV3Module,
        RecaptchaFormsModule, RecaptchaModule,
        NgbModule,
        ToastrModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        PerfectScrollbarModule, ReactiveFormsModule,
        AppRoutingModule,
        ChartsModule,
        JwtModule.forRoot({config:{tokenGetter() {
                    return localStorage.getItem('access_token');
                },
                allowedDomains: ['localhost', 'https://app.gadsup.com'],
                disallowedRoutes: ['localhost/auth/login']
            }
        }),

    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        }
        , { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcJmfMZAAAAADv-r5tKnnpnsfzO7T0f5lxRmslf' },

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
