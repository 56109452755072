import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

    // {
    //     path: '/Dashboard',
    //     title: 'Dashboard',
    //     icon: 'icon-speedometer',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    {
        path: '',
        title: 'Employees Section',
        icon: '',
        class: 'nav-small-cap',
        label: '',
        labelClass: '',
        extralink: true,
        submenu: []
    },
    {
        path: '/Employees',
        title: 'Employees Details',
        icon: 'icon-people',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            
             {
                path: 'Employees/Attendance',
                title: 'View Employees ',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            }, 
         
        ]
    },
    {
        path: '/HRM-Settings',
        title: 'HRM Settings',
        icon: 'icon-globe',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            {
                path: 'HRM-Settings/Company',
                title: 'Add/Edit Company',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: 'HRM-Settings/Job-Titles',
                title: 'Job Titles',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
           
            {
                path: 'HRM-Settings/Profile',
                title: 'Profile',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
             
           
            // {
            //     path: 'Youtube-Videos-Search/Top-Charts',
            //     title: 'Top Chart Videos',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // },
            // {
            //     path: 'Youtube-Videos-Search/Trending-Youtube-Videos',
            //     title: 'Trending Videos',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // },
           
            // {
            //     path: 'Youtube-Videos-Search/Search-Related-Videos',
            //     title: 'Related Videos',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // },
            // {
            //     path: 'Youtube-Videos-Search/Youtube-Video-Details',
            //     title: 'Single Video Details',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // },
            // {
            //     path: 'Youtube-Videos-Search/Youtube-Placement-List',
            //     title: 'Placement List',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // },
        ]
    },

    /*
    {
        path: '/Youtube-Channel',
        title: 'Channel Search',
        icon: 'icon-screen-desktop',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            {
                path: 'Youtube-Channel/Search-By-Keyword',
                title: 'Channel By Keyword',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },  
            {
                path: 'Youtube-Channel/Search-Featured-Channel',
                title: 'Featured Channel',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },    
            {
                path: 'Youtube-Channel/Channel-Details',
                title: 'Channel Details',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },          
        ]
    },

    {
        path: '',
        title: 'Google Smart URL Scrapper',
        icon: '',
        class: 'nav-small-cap',
        label: '',
        labelClass: '',
        extralink: true,
        submenu: []
    },
    {
        path: '/Smart-URL-Scrapper',
        title: 'Smart URL Scrapper',
        icon: 'icon-globe',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            {
                path: 'Smart-URL-Scrapper/Search-by-keyword',
                title: 'URL Scrapper',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: 'Smart-URL-Scrapper/Placement-List',
                title: 'URL Placement List',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
        ]
    },
  
    
    {
        path: '',
        title: 'HRM Extension Bookmark Ads',
        icon: '',
        class: 'nav-small-cap',
        label: '',
        labelClass: '',
        extralink: true,
        submenu: []
    },
    {
        path: '/Chrome-Extension',
        title: 'Chrome Extension',
        icon: 'icon-tag',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            {
                path: 'Chrome-Extension/Bookmarked-Youtube-ADs',
                title: 'Bookmarked ADs',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: 'Chrome-Extension/Bookmarked-Youtube-Videos',
                title: 'Bookmarked Videos',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
        ]
    },

    {
        path: '',
        title: 'Others',
        icon: '',
        class: 'nav-small-cap',
        label: '',
        labelClass: '',
        extralink: true,
        submenu: []
    },
    {
        path: '/GAds-Up',
        title: 'Other Stuffs',
        icon: 'mdi mdi-play-circle-outline',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            {
                path: 'GAds-Up/Searched-Keywords',
                title: 'Searched Keywords',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: 'GAds-Up/Notifications-List',
                title: 'Notifications',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: 'GAds-Up/ROI-Calculator',
                title: 'ROI Calculator',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
        ]
    },
  
*/


];

export const ROUTESFOREMPLOYEES: RouteInfo[] = [

    // {
    //     path: '/Dashboard',
    //     title: 'Dashboard',
    //     icon: 'fa fa-tasks',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    {
        path: '',
        title: 'Attendance Section',
        icon: '',
        class: 'nav-small-cap',
        label: '',
        labelClass: '',
        extralink: true,
        submenu: []
    },
    {
        path: 'Employees/Attendance',
        title: 'Employees Attendance Details',
        icon: 'fa fa-tasks',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            // {
            //     path: 'Employees/Attendance',
            //     title: 'Employees Attendance Details',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // },
            // {
            //     path: 'Youtube-Videos-Search/Top-Charts',
            //     title: 'Top Chart Videos',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // },
            // {
            //     path: 'Youtube-Videos-Search/Trending-Youtube-Videos',
            //     title: 'Trending Videos',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // },
           
            // {
            //     path: 'Youtube-Videos-Search/Search-Related-Videos',
            //     title: 'Related Videos',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // },
            // {
            //     path: 'Youtube-Videos-Search/Youtube-Video-Details',
            //     title: 'Single Video Details',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // },
            // {
            //     path: 'Youtube-Videos-Search/Youtube-Placement-List',
            //     title: 'Placement List',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // },
        ]
    },
    // {
    //     path: '',
    //     title: 'Employee Tracking',
    //     icon: '',
    //     class: 'nav-small-cap',
    //     label: '',
    //     labelClass: '',
    //     extralink: true,
    //     submenu: []
    // },
    // {
    //     path: 'Employees/Screenshot-Monitor',
    //     title: 'Reports Display',
    //     icon: 'icon-screen-desktop',
    //     class: 'has-arrow',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: [
    //         // {
    //         //     path: 'Employees/Attendance',
    //         //     title: 'Employees Attendance Details',
    //         //     icon: '',
    //         //     class: '',
    //         //     label: '',
    //         //     labelClass: '',
    //         //     extralink: false,
    //         //     submenu: []
    //         // },
    //         // {
    //         //     path: 'Youtube-Videos-Search/Top-Charts',
    //         //     title: 'Top Chart Videos',
    //         //     icon: '',
    //         //     class: '',
    //         //     label: '',
    //         //     labelClass: '',
    //         //     extralink: false,
    //         //     submenu: []
    //         // },
    //         // {
    //         //     path: 'Youtube-Videos-Search/Trending-Youtube-Videos',
    //         //     title: 'Trending Videos',
    //         //     icon: '',
    //         //     class: '',
    //         //     label: '',
    //         //     labelClass: '',
    //         //     extralink: false,
    //         //     submenu: []
    //         // },
           
    //         // {
    //         //     path: 'Youtube-Videos-Search/Search-Related-Videos',
    //         //     title: 'Related Videos',
    //         //     icon: '',
    //         //     class: '',
    //         //     label: '',
    //         //     labelClass: '',
    //         //     extralink: false,
    //         //     submenu: []
    //         // },
    //         // {
    //         //     path: 'Youtube-Videos-Search/Youtube-Video-Details',
    //         //     title: 'Single Video Details',
    //         //     icon: '',
    //         //     class: '',
    //         //     label: '',
    //         //     labelClass: '',
    //         //     extralink: false,
    //         //     submenu: []
    //         // },
    //         // {
    //         //     path: 'Youtube-Videos-Search/Youtube-Placement-List',
    //         //     title: 'Placement List',
    //         //     icon: '',
    //         //     class: '',
    //         //     label: '',
    //         //     labelClass: '',
    //         //     extralink: false,
    //         //     submenu: []
    //         // },
    //     ]
    // },

    /*
    {
        path: '/Youtube-Channel',
        title: 'Channel Search',
        icon: 'icon-screen-desktop',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            {
                path: 'Youtube-Channel/Search-By-Keyword',
                title: 'Channel By Keyword',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },  
            {
                path: 'Youtube-Channel/Search-Featured-Channel',
                title: 'Featured Channel',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },    
            {
                path: 'Youtube-Channel/Channel-Details',
                title: 'Channel Details',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },          
        ]
    },

    {
        path: '',
        title: 'Google Smart URL Scrapper',
        icon: '',
        class: 'nav-small-cap',
        label: '',
        labelClass: '',
        extralink: true,
        submenu: []
    },
    {
        path: '/Smart-URL-Scrapper',
        title: 'Smart URL Scrapper',
        icon: 'icon-globe',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            {
                path: 'Smart-URL-Scrapper/Search-by-keyword',
                title: 'URL Scrapper',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: 'Smart-URL-Scrapper/Placement-List',
                title: 'URL Placement List',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
        ]
    },
  
    
    {
        path: '',
        title: 'HRM Extension Bookmark Ads',
        icon: '',
        class: 'nav-small-cap',
        label: '',
        labelClass: '',
        extralink: true,
        submenu: []
    },
    {
        path: '/Chrome-Extension',
        title: 'Chrome Extension',
        icon: 'icon-tag',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            {
                path: 'Chrome-Extension/Bookmarked-Youtube-ADs',
                title: 'Bookmarked ADs',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: 'Chrome-Extension/Bookmarked-Youtube-Videos',
                title: 'Bookmarked Videos',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
        ]
    },

    {
        path: '',
        title: 'Others',
        icon: '',
        class: 'nav-small-cap',
        label: '',
        labelClass: '',
        extralink: true,
        submenu: []
    },
    {
        path: '/GAds-Up',
        title: 'Other Stuffs',
        icon: 'mdi mdi-play-circle-outline',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            {
                path: 'GAds-Up/Searched-Keywords',
                title: 'Searched Keywords',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: 'GAds-Up/Notifications-List',
                title: 'Notifications',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: 'GAds-Up/ROI-Calculator',
                title: 'ROI Calculator',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
        ]
    },
  
*/


];
